export const getDepartures = (performances) => {
 return performances
  .map((performance) => {
   const date = new Date(performance?.performanceDateTime);
   const now = new Date();
   const expired = date < now;
   return { ...performance, expired };
  })
  .filter((performance) => !performance.expired);
};

export const getDeparturesByDate = (performances, date) => {
 if (typeof date === 'string') date = new Date(date);
 const targetDate = date.toISOString().split('T')[0];
 const filtered = performances.filter((performance) => {
  const performanceDate = new Date(performance.performanceDateTime).toISOString().split('T')[0];
  return performanceDate === targetDate;
 });

 return filtered.map((performance) => {
  const date = new Date(performance?.performanceDateTime);
  const now = new Date();
  const expired = date < now;
  return { ...performance, expired };
 });
};

export const getToursByDate = (schedule, date) => {
 if (typeof date === 'string') date = new Date(date);
 const targetDate = date.toISOString().split('T')[0];
 const filtered = schedule.filter((day) => {
  const singleDay = new Date(day.date).toISOString().split('T')[0];
  return singleDay === targetDate;
 });

 console.log(`🍤 ~ getToursByDate ~ filtered:`, filtered);
 return filtered;

 //  return filtered.map((day) => {
 //   const date = new Date(performance?.performanceDateTime);
 //   // const now = new Date();
 //   const expired = date < now;
 //   return { ...day, expired };
 //  });
};
